export const getScrollbarWidth = () => {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    const inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
};

export const isHtml = (string) => {
    return /<[a-z][\s\S]*>/i.test(string);
};

export const setBackgroundImage = (imagePath) => {
    document.getElementById("root").style.backgroundImage = 'url(' + imagePath + ')';
    document.getElementById("root").style.backgroundSize = 'cover';
    document.getElementById("root").style.backgroundPosition = 'center center';
    document.getElementById("root").style.backgroundRepeat = 'no-repeat';
};