import React, {Component} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem
} from 'reactstrap';
import {NavLink, Redirect, Route, Switch} from 'react-router-dom';
import Footer from '../../../containers/ComponentManager/Common/Footer';
import CookieBanner from "../../SiteComponents/CookieBanner/CookieBanner";

class ComponentHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigationConfig: this.renderNavigation(props.navigationConfig.navigationItems),
            isOpen: false,
            icon: 'open-burger'
        }
    }

    toggle = () => {
        if (window.innerWidth <= 767) {

            let iconClass = 'open-burger';

            if (this.state.isOpen) {
                iconClass = 'open-burger';
            } else {
                iconClass = 'close-burger';
            }

            this.setState({
                isOpen: !this.state.isOpen,
                icon: iconClass
            });
        }
    };

    renderNavigation = (navigationConfiguration) => {
        let navItems = navigationConfiguration;
        let navigationElements = [];
        for (let navItem in navItems) {
            navigationElements.push(navItems[navItem])
        }
        return navigationElements;
    };

    render() {
        const burger = (
            <div className="nav-icon">
                <div/>
            </div>
        );

        return (
            <div>
                <Navbar color="light" light expand="md">
                    <NavLink className={"navbar-brand"} to={this.props.homeRoute} exact>{this.props.appName}</NavLink>
                    <NavbarToggler onClick={this.toggle} children={burger} className={this.state.icon} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {this.state.navigationConfig.map(navElement => {
                                    let navigationItem = null;
                                    if (navElement.label) {
                                        navigationItem = (
                                            <NavItem key={navElement.route}>
                                                <NavLink onClick={() => this.toggle()} className={"nav-link"} to={navElement.route} exact>{navElement.label}</NavLink>
                                            </NavItem>
                                        )
                                    }
                                    return navigationItem;
                                })
                            }
                        </Nav>
                    </Collapse>
                </Navbar>

                <Switch>
                    {this.state.navigationConfig.map(nav => {
                        return <Route key={nav} path={nav.route} exact component={this.props.componentsMap[nav.component]}/>;
                    })
                    }
                    <Redirect to={this.props.redirectTo}/>
                </Switch>

                <Footer>
                    {this.props.footerContent}
                </Footer>

                <CookieBanner />
            </div>
        );
    }
}
export default ComponentHolder;