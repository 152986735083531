import data from '../data/data.json';
export default class DataService {
    static getData() {
        const modifiedData = [];
        for (let dataUnit in data) {
            let newDataUnit = {
                id: dataUnit,
                name: data[dataUnit].data.name,
                headerImage: data[dataUnit].data.header_image,
                shortDescription: data[dataUnit].data.short_description/*,
                dataFeed: data[dataUnit]*/
            };
            modifiedData.push(newDataUnit)
        }
        return modifiedData;
    }

    static getDetailedDataById(id) {
        let singleDataFeed = null;

        if (data[id]) {
            singleDataFeed = {
                id: id,
                name: data[id].data.name,
                detailedDescription: data[id].data.detailed_description,
                shortDescription: data[id].data.short_description,
                screenschots: data[id].data.screenshots,
                movies: data[id].data.movies,
                background: data[id].data.background,
                pcRequirements: data[id].data.pc_requirements
            }
        }

        return singleDataFeed;
    }

    static getScreenshots(incomingDetailedFeed) {
        let screenshots = [];
        if (incomingDetailedFeed !== null) {
            let preparedImages = incomingDetailedFeed.screenschots;

            for (let image in preparedImages) {
                let newImage = {
                    original: preparedImages[image].path_full,
                    thumbnail: preparedImages[image].path_thumbnail
                };
                screenshots.push(newImage);
            }
        }

        return screenshots;
    }

    static getCategories() {
        const categories = [];
        for (let id in data) {
            let newCategory = {
                id: id,
                name: data[id].data.categories/*,
                dataFeed: data[dataUnit]*/
            };
            categories.push(newCategory)
        }
        return categories;
    }

    static getGenres() {
        const genres = [];
        for (let id in data) {
            let newGenre = {
                id: id,
                name: data[id].data.genres/*,
                dataFeed: data[dataUnit]*/
            };
            genres.push(newGenre)
        }
        return genres;
    }
}