import React from 'react';

const footer = (props) => {
    return (
        <footer className="footer">
            {props.children}
        </footer>
    )
};

export default footer;