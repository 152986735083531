import React from 'react';
import logo from '../../../../../assets/Images/g_logo.png';

const publisher = () => {
    return (
        <div className={"publisher-wrapper"}>
            <h3>Meet our Publisher</h3>
            <a href="http://www.grabthegames.com" target="_blank" rel="noopener noreferrer">
                <img src={logo} className="logo" alt="Grab The Games" title="Grab The Games"/>
            </a>
        </div>
    );
};

export default publisher;