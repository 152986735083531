import React from 'react';
import { Jumbotron } from 'reactstrap';
import DiscordWidget from '../../../components/SiteComponents/Widgets/DiscordWidget';

const jumbotron = (props) => {

    let title = '';
    let lead = '';
    let text = '';

    if (props.content && props.content !== null) {
        title = props.content.title;
        lead = props.content.lead;
        text = props.content.text;
    }

    return (
        <div>
            <Jumbotron>
                <h2 className="display-4">{title}</h2>
                <p className="lead">{lead}</p>
                <hr className="my-2" />
                <p>{text}</p>
                <DiscordWidget/>
            </Jumbotron>
        </div>
    );
};

export default jumbotron;