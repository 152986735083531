import React, {Component} from 'react';
import { Container } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import {NavLink, Redirect} from 'react-router-dom';
import DataService from '../../../service/DataService';
import PurchaseWidget from '../Widgets/PurchaseWidget';
import YouTubeWidget from '../Widgets/YouTubeWidget';
import {isHtml, setBackgroundImage} from "../../../shared/util/common";
import image from '../../../assets/Images/mazen-games-backgroun.png';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

export default class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameDetails: [],
            screenshots: [],
            youtubeVideo: null
        }
    }

    getGameDetails = () => {
        let gameId = parseInt(this.props.location.pathname.split("/")[2]);
        return DataService.getDetailedDataById(gameId);
    };

    componentDidMount() {
        // fix for iPad
        window.scrollTo(0, 0);
        this.setState({ gameDetails: this.getGameDetails(), screenshots: DataService.getScreenshots(this.getGameDetails()) })
    }

    render() {
        let data = <Redirect to={"/"} />;
        let purchaseWidget = null;
        let youTubeWidget = null;
        let breadCrumbs = null;
        let images = this.state.screenshots;

        let imageSlider = null;

        if (images.length > 0) {
            imageSlider = (
                <div>
                    <h1>Featured Images</h1>
                    <ImageGallery defaultImage={image} showPlayButton={false} lazyLoad={true} items={images} />
                </div>
            );
        }

        // detailedDescription
        // shortDescription
        if (this.state.gameDetails) {
            data = this.state.gameDetails.detailedDescription;
            setBackgroundImage(this.state.gameDetails.background);

            if (this.state.gameDetails.id) {
                breadCrumbs = (
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <NavLink to={"/"} exact>{"Home"}</NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{this.state.gameDetails.name}</BreadcrumbItem>
                    </Breadcrumb>
                );
                purchaseWidget = <PurchaseWidget itemName={this.state.gameDetails.name} itemId={this.state.gameDetails.id}/>;
                youTubeWidget = (
                    <div>
                        <h1>Featured Trailer</h1>
                        <YouTubeWidget itemName={this.state.gameDetails.name} itemId={this.state.gameDetails.id}/>
                    </div>
                );
            }
        }

        let content = data;

        if (isHtml(data)) {
            const res = data.replace(/<img/g, '<img class="img-fluid"');
            content = (<div dangerouslySetInnerHTML={{__html: res}} />);
        }

        return (
            <div className={"game-details"}>
                <div className={"breadcrumb-wrapper"}>
                    {breadCrumbs}
                </div>
                {purchaseWidget}
                <Container>
                    {youTubeWidget}
                    {imageSlider}
                    {content}
                </Container>
            </div>
        );
    }
}
