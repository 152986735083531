import React, {Component} from 'react';
import YouTubeService from '../../../service/YouTubeService';
import YouTube from 'react-youtube';

const Aux = (props) => {
    return props.children;
};

export default class YouTubeWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            resumeVideo: true,
            isPlaying: false,
            player: null
        };
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        });
    };

    handleScroll = () => {
        if (window.scrollY >= 265) {
            this.setState({
                resumeVideo: false
            });
        } else {
            this.setState({
                resumeVideo: true
            });
        }
    };

    componentDidMount() {
        // Additionally I could have just used an arrow function for the binding `this` to the component...
        this.setState({data: YouTubeService.getData(this.props.itemId)});
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    onReady(event) {
        this.setState({player: event.target});
    }

    onPlay(event) {
        console.log("onPlay");
        this.setState({isPlaying: true});
    }

    render() {
        let youTubeVideo = null;

        if (this.state.data) {
            const id = this.state.data.trailers[0].videoId;
            let resumeVideo = this.state.resumeVideo;

            this.manageVideoState(resumeVideo);

            youTubeVideo = (
                <YouTube id={"youtube-video-"+id} videoId={id}
                         onReady={(event) => this.onReady(event)}
                         onPlay={(event) => this.onPlay(event)} />
            );
        }

        return (
            <Aux>
                <div className={"youtube-widget-wrapper embed-responsive embed-responsive-16by9"}>
                    {youTubeVideo}
                </div>
            </Aux>
        );
    }

    manageVideoState = (resumeVideo) => {
        if (this.state.player) {
            if (this.state.isPlaying) {
                if (resumeVideo) {
                    this.state.player.playVideo();
                } else {
                    this.state.player.pauseVideo();
                }
            }
        }
    };
}