import React, {Component} from 'react';
import { Container } from 'reactstrap';
import DataService from '../../../service/DataService';
import ContentService from '../../../service/ContentService';
import BackgroundImageService from '../../../service/BackgrounImageService';
import ItemsList from '../../../containers/SiteContainer/ItemsList/ItemsList';
import Jumbotron from '../../../containers/SiteContainer/Jumbotron/Jumbotron';
import {setBackgroundImage} from "../../../shared/util/common";

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            games: [],
            jumbotronContent: null,
            randomBackgroundImage: null,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({games: DataService.getData(),
                jumbotronContent: ContentService.getJumbotronContent(),
                randomBackgroundImage: BackgroundImageService.getRandomBackgroundImage()}
            );
    }

    render() {
        setBackgroundImage(this.state.randomBackgroundImage);
        return (
            <div className={"home"}>
                <Container>
                    <Jumbotron content={this.state.jumbotronContent}/>
                    <ItemsList className={"games-list"} itemsName={"Featured Games"} items={this.state.games}/>
                </Container>
            </div>
        );
    }
}
