import about from '../data/about.json';

export default class AboutService {
    static getBackgroundImage() {
        return about.about.backgroundImage;
    }

    static getAboutImage() {
        return about.about.image;
    }

    static getData() {
        return about.about.descriptionBlocks;
    }
}