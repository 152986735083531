import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './style/main/main.scss';
import ComponentHolder from './components/ComponentManager/ComponentHolder/ComponentHolder';

import {mainNav} from "./constants/MainNavigation";

import Home from "./components/SiteComponents/Home/Home";
import About from "./components/SiteComponents/About/About";
import Details from "./components/SiteComponents/Details/Details";
import Footer from "./containers/SiteContainer/Common/Footer/Footer";
import CookiePolicy from "./components/SiteComponents/CookieBanner/CookiePolicy/CookiePolicy";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

const COMPONENT_MAP = {
    [mainNav.navigationItems.home.component]: Home,
    [mainNav.navigationItems.about.component]: About,
    [mainNav.navigationItems.details.component]: Details,
    [mainNav.navigationItems.cookiesPolicy.component]: CookiePolicy
};


class App extends Component {

    render() {
        return (
          <div className="App">
            <ComponentHolder navigationConfig={mainNav}
                             homeRoute={"/"}
                             appName={"Mazen Games"}
                             componentsMap={COMPONENT_MAP}
                             redirectTo={"/"}
                             footerContent={Footer()}/>
          </div>
        );
  }
}

export default App;
