import React, { Component } from 'react';
import SocialMediaService from "../../../../service/SocialMediaService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SocialMedia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            socialMediaTitle: '',
            socialMediaItems: [],
        }
    }

    componentDidMount() {
        this.setState({socialMediaTitle: SocialMediaService.getData().title,
            socialMediaItems: SocialMediaService.getData().platforms});
    }

    render() {
        let items = null;

        if (this.state.socialMediaItems) {
            items = this.state.socialMediaItems.map(el => {
                return(
                    <li key={el.name} className={el.name}>
                        <a href={el.link} target={"_blank"}>
                            <FontAwesomeIcon icon={[el.prefix, el.icon]} />
                        </a>
                    </li>
                )
            })
        }

        return(
            <div className={"social-media-wrapper"}>
                <h3>{this.state.socialMediaTitle}</h3>
                <ul className={"social-media"}>
                    {items}
                </ul>
            </div>
        )
    }
}
export default SocialMedia;