import React from 'react';
import { Card, CardImg, CardBody, CardFooter, CardTitle, CardText, Row, Col, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const itemsList = (props) => {
    return (
        <div className={props.className}>
            <h1 className={"games-list-title"}>{props.itemsName}</h1>
            <Row className={"equal"}>
                {
                    props.items.map(dataFeed => (
                        <Col className={"list-item-wrapper"} md="4" key={dataFeed.id}>
                            <NavLink to={{ pathname: '/games/' + dataFeed.id}} key={dataFeed.id}>
                                <div className={"list-item"}>
                                    <Card outline color="secondary" className={"card-hover"} >
                                        <CardImg top width="100%" src={dataFeed.headerImage} alt="Card image cap"/>
                                        <CardBody>
                                            <CardTitle>{dataFeed.name}</CardTitle>
                                            <CardText>{dataFeed.shortDescription}</CardText>
                                        </CardBody>
                                        <CardFooter>
                                            <Button block outline color="info">Show More</Button>
                                        </CardFooter>
                                    </Card>
                                </div>
                            </NavLink>
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
};

export default itemsList;