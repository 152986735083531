import React from 'react';
import SocialMedia from '../../../../components/SiteComponents/List/SocialMedia/SocialMedia';
import Publiisher from './Publisher/Publisher';
import {
    Row,
    Col,
    Container
} from 'reactstrap';

const footer = () => {
    return (
        <Container>
            <Row>
                <Col md={6} xs={12}>
                    <SocialMedia />
                </Col>

                <Col md={6} xs={12}>
                    <Publiisher />
                </Col>
            </Row>
        </Container>
    )
};

export default footer;