import React, {Component} from 'react';

const Aux = (props) => {
    return props.children;
};

export default class DiscordWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            format: this.getWidgetFormat()
        };
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        });
    };

    getWidgetFormat = () => {
        let widgetFormat = '';
        if (window.innerWidth <= 767) {
            widgetFormat = 'embed-responsive-1by1';
        } else if (window.innerWidth <= 991) {
            widgetFormat = 'embed-responsive-16by9';
        }
        else {
            widgetFormat = 'embed-responsive-21by9';
        }

        return widgetFormat;
    };

    updateFormat = () => {
        this.setState({
            format: this.getWidgetFormat()
        });
    };

    componentDidMount() {
        // Additionally I could have just used an arrow function for the binding `this` to the component...
        window.addEventListener("resize", this.updateFormat);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateFormat);
    }

    render() {
        return (
            <Aux>
                <a className="btn btn-outline-dark" target={"_blank"} href="https://discord.gg/rHFbwMz" role="button">Open Discord</a>
                <div className={"discord-widget-wrapper embed-responsive " + this.state.format}>
                    <iframe title={"discord"}
                            className={"embed-responsive-item"}
                            src="https://discordapp.com/widget?id=379873143101194241&theme=dark"
                            frameBorder="0" onLoad={this.hideSpinner}/>
                </div>
            </Aux>
        );
    }
}