export const mainNav = {
    navigationItems: {
        home: {
            label: 'Home',
            route: '/',
            component: 'Home'
        },
        about: {
            label: 'About',
            route: '/about',
            component: 'About'
        },
        details: {
            route: '/games/:id',
            component: 'Details'
        },
        cookiesPolicy: {
            route: '/cookie-policy',
            component: 'CookiePolicy'
        }
    }
};