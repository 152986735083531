import React, {Component} from 'react';
import { Container } from 'reactstrap';
import DataService from "../../../service/DataService";
import AboutService from "../../../service/AboutService";
import AboutItemsList from '../../../containers/SiteContainer/ItemsList/AboutItemsList';

import {setBackgroundImage} from "../../../shared/util/common";

export default class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            finalWord: null,
            image: null,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({games: DataService.getData(),
            data: AboutService.getData(),
            image: AboutService.getAboutImage(),
            aboutBackgroundImage: AboutService.getBackgroundImage()}
        );
    }

    render() {

        let about = null;
        if (this.state.data) {
            about = (
                <AboutItemsList itemsName={"About"} items={this.state.data} image={this.state.image}/>
            );
        }

        setBackgroundImage(this.state.aboutBackgroundImage);
        return (
            <div className={"about"}>
                <Container >
                    {about}
                </Container>
            </div>
        );
    }
}
