import React, {Component} from 'react';
import { Spinner } from 'reactstrap';
import {getScrollbarWidth} from "../../../shared/util/common";

export default class PurchaseWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            width: window.innerWidth
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        });
    };

    updateDimensions() {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    }

    componentDidMount() {
        // Additionally I could have just used an arrow function for the binding `this` to the component...
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    // { this.state.loading ? (<Spinner className={"spinner-loading"} color="light"/>) : null }

    render() {
        return (
            <div className={"steam-widget"}>
                { this.state.loading ? (<Spinner className={"spinner-loading"} color="light"/>) : null }
                <iframe className="" title={this.props.itemName}
                        src={"https://store.steampowered.com/widget/"+this.props.itemId}
                        frameBorder="0" width={window.innerWidth - getScrollbarWidth()} height={200} onLoad={this.hideSpinner}/>
            </div>
        )
    }
}