import React from 'react';
import {Row, Col} from 'reactstrap';

const aboutItemsList = (props) => {

    let orderClass = '';

    return (
        <div className={props.className}>
            <h1 className={"list-about-title"}>{props.itemsName}</h1>
            <Row className={"equal"}>
                <Col className={"list-about-wrapper"}>
                {
                    props.items.map(dataFeed => {
                        if (dataFeed.id % 2 === 0) {
                            orderClass = 'order-first order-md-12';
                        } else {
                            orderClass = '';
                        }
                        return (
                            <Row key={dataFeed.id} className={"list-item"}>
                                <Col xs={12} md={5} lg={4} xl={4} className={"list-about-item-image " + orderClass}>
                                    <img className={"img-fluid img-thumbnail"} src={dataFeed.image} alt=""/>
                                </Col>

                                <Col xs={12} md={7} lg={8} xl={8} className={"list-about-item-text"}>
                                    <span className={"about-text-block"} >
                                        {dataFeed.text}
                                    </span>
                                </Col>
                            </Row>
                        )
                    })
                }
                </Col>
            </Row>
        </div>
    );
};

export default aboutItemsList;