import React, {Component} from 'react';
import {Col, Row, Button, Container}from "reactstrap";
import { NavLink } from 'react-router-dom'


export default class CookieBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCookieBanner: true
        }
    }

    setCookies = (cName, cValue, exDays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exDays * 24 *61 * 61 *1000));
        const expires = "expires="+d.toUTCString();
        document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
    };

    getCookie = (cname) => {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    checkCookie = () => {
        const accept = this.getCookie("viewed_cookie_policy");
        if (accept === "yes") {
            this.setState({showCookieBanner: false});
        }
    };

    acceptCookies = () => {
        this.setCookies("viewed_cookie_policy", "yes", 30);
        this.checkCookie();
    };

    componentDidMount() {
        this.checkCookie();
    }

    render() {
        let div = null;

        if (this.state.showCookieBanner) {
            div = (
                <div className={"cookie-banner"}>
                    <Container>
                        <Row>
                            <Col md={10} xs={12} className={"cookie-banner-text"}>
                            <span>We use cookies to ensure that we give you the best experience on our website.
                                Click <NavLink to="/cookie-policy" activeClassName="selected"> here </NavLink> for more information.
                            </span>
                            </Col>

                            <Col md={2} xs={12}>
                                <Button block outline color="info" onClick={this.acceptCookies}> GOT IT</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }

        return div;
    }
}

